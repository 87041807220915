import React from 'react';
import PropTypes from 'prop-types'
import { IoIosArrowRoundForward } from "react-icons/io";
import SectionTitle from '../../../../components/ui/section-title'
import Anchor from '../../../../components/ui/anchor'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import { SectionWrap } from './section.style'
import BoxLargeImage from '../../../../components/box-large-image/layout-two'

import image1 from '../../../../data/images/box-image/property-maintenance-tools.jpg'
import image2 from '../../../../data/images/box-image/property-refurbishment.jpg'
import image3 from '../../../../data/images/box-image/painting-and-decorating.jpg'
import image4 from '../../../../data/images/box-image/tower-block.jpg'
import image5 from '../../../../data/images/box-image/property-management.jpg'

const BoxSection = ({ linkStyle, sectionTitleStyle, sectionBottomStyle, boxStyles }) => {
    const boxContents = [
        {
            id: 1,
            imageSrc: image1,
            title: 'Property Maintenance',
            desc: 'Property maintenance and decorating services for both domestic and commercial customers at competitive prices.',
            url: 'maintenance'
        },
        {
            id: 2,
            imageSrc: image2,
            title: 'Property Refurbishment',
            desc: 'This service is tailored for landlords who are suffering from hevy void peirods, perhaps the property in question is in some need of some real TLC. That’s where Propertypac take action.',
            url: 'management/property-refurbishment'
        },
        {
            id: 3,
            imageSrc: image3,
            title: 'Painting and Decorating',
            desc: 'Engaging and vibrant interior environments are at the centre of an peoples\' enjoyment of their time at work or home, and so it is crucial that you create the right environment for everyone.',
            url: 'painting-and-decorating'
        },
        {
            id: 4,
            imageSrc: image4,
            title: 'Block Maintenance',
            desc: 'You will only ever have one direct point of contact for your services, removing the communication problems you once had when using multiple contractors for your sites.',
            url: 'maintenance/block-maintenance'
        },
        {
            id: 5,
            imageSrc: image5,
            title: 'Property Management',
            desc: 'We will fully manage your properties whether they are Sing buy to Lets and even HMO’s. We will fulfil all your responsibilities as a landlord and more. Take a look at the list below for a sneak peak of what we can do for you.',
            url: 'management'
        }
    ]
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col xl={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            subtitle="All In One Property Package"
                            title="<span>Property Services</span>"
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        boxContents.map(boxContent => {
                            return (
                                <Col lg={4} md={6} className="box-item" key={`box-image-${boxContent.id}`}>
                                    <BoxLargeImage
                                        {...boxStyles}
                                        imageSrc={boxContent.imageSrc}
                                        title={boxContent.title}
                                        desc={boxContent.desc}
                                        url={boxContent.url}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </SectionWrap>
    )
}

BoxSection.propTypes = {
    sectionTitleStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    linkStyle: PropTypes.object,
    boxStyles: PropTypes.object
}

BoxSection.defaultProps = {
    sectionTitleStyle: {
        mb: '20px',
        responsive: {
            medium: {
                mb: '10px'
            }
        }
    },
    boxStyles: {
        headingStyle: {
            color: '#000'
        }
    },
    linkStyle: {
        layout: 'underline',
        fontSize: '18px',
        fontWeight: 500,
        lineheight: 1.40,
        color: 'primary',
        hover: {
            layout: 2
        }
    },
    sectionBottomStyle: {
        mt: '40px',
        align: 'center'
    }
}

export default BoxSection;